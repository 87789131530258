import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { PortableText } from "@portabletext/react";

const FAQs = ({ faqs }) => {
  const [toggleFAQ, setToggleFAQ] = useState(
    new Array(FAQs.length).fill(false)
  );
  useEffect(() => {}, [toggleFAQ]);
  return (
    <section id='FAQs' className='w-full py-12  bg-brand-peach-light '>
      <div className='container mx-auto'>
        <h4 className=' text-5xl font-semibold uppercase text-center pb-4'>
          FAQs
        </h4>
        <div className=' flex md:ml-12 mt-8 flex-col w-full max-w-fit'>
          {faqs.faqs.map((FAQ, index) => {
            return (
              <div
                key={index}
                className='w-full flex flex-col mb-4 max-w-max lg:max-w-4xl '
              >
                <button
                  className='Q w-full text-left  z-10 lg:mb-0'
                  onClick={(e) => {
                    e.preventDefault();
                    const toggleMap = [...toggleFAQ];
                    toggleMap[index] = !toggleMap[index];
                    toggleMap.map((item, i) => {
                      if (i !== index) {
                        toggleMap[i] = false;
                      }
                    });
                    setToggleFAQ(toggleMap);
                  }}
                >
                  <span className='text-xl   tracking-wide mt-2 flex items-start'>
                    <span className='font-extralight text-5xl mr-4'>
                      {toggleFAQ[index] ? (
                        <FontAwesomeIcon icon={faMinus} className='w-6 ' />
                      ) : (
                        <FontAwesomeIcon
                          icon={faPlus}
                          className={`w-6 duration-500 ${
                            toggleFAQ[index] ? "h-0" : "h-6"
                          }`}
                        />
                      )}
                    </span>
                    <span className='font-semibold'>{FAQ.question}</span>
                  </span>
                </button>
                <div
                  className='A duration-500 lg:pl-10 w-full text-left max-h-max'
                  style={{
                    marginTop: toggleFAQ[index] ? "4px" : "0px",
                    opacity: toggleFAQ[index] ? 1 : 0,
                  }}
                >
                  <div
                    className='duration-500 text-xl tracking-wide overlfow-hidden'
                    style={{
                      marginTop: toggleFAQ[index] ? "12px" : "-4px",
                      opacity: toggleFAQ[index] ? 1 : 0,
                      minHeight: toggleFAQ[index] ? "80px" : "0px",
                      height: toggleFAQ[index] ? "auto" : "0px",
                    }}
                  >
                    <PortableText value={FAQ.answer} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FAQs;
