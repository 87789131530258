import AboutImgOne from "../assets/images/about_1.jpg";
import AboutImgTwo from "../assets/images/about_2.jpg";
import Image from "next/image";
import Brand from "../components/snippets/Brand";
import Link from "next/link";
import { useEffect, useRef } from "react";
import { animateSlideInBottom, animateSlideInTop } from "./animations";

const About = ({}) => {
  const sectionRef = useRef(null);
  useEffect(() => {
    const SectionRef = sectionRef.current;

    animateSlideInTop(
      SectionRef.querySelectorAll("#About--Leading-Ladies h4.SlideInTop"),
      1
    );
    animateSlideInBottom(
      SectionRef.querySelectorAll("#About--Leading-Ladies p.SlideInBottom"),
      1
    );
    animateSlideInTop(
      SectionRef.querySelectorAll("#About--Leading-Ladies div.SlideInTop"),
      1.2
    );
    animateSlideInBottom(
      SectionRef.querySelectorAll("#About--Leading-Ladies div.SlideInBottom"),
      1.3
    );
  }, []);
  return (
    <section id='About--Leading-Ladies' className='lg:py-12 ' ref={sectionRef}>
      <div className='lg:container mx-auto'>
        <div className='flex flex-col lg:flex-row justify-center items-center mx-auto '>
          <div className='w-full lg:w-5/12 flex flex-col lg:items-center lg:justify-center order-2 lg:order-1 relative min-h-[468px] sm:min-h-[500px] md:min-h-[768px] '>
            <div className='SlideInTop relative w-3/4 h-[300px] md:h-[500px] lg:h-80 lg:w-96 lg:mb-2 border-[12px] border-white  lg:border-0 Animated--Text overflow-hidden'>
              <Image
                src={AboutImgOne}
                alt=''
                layout='responsive'
                width={500}
                height={400}
                objectFit='cover'
                sizes='(min-width: 75em) 33vw,
              (min-width: 48em) 50vw,
              100vw'
              />
            </div>
            <div className='SlideInBottom absolute lg:relative w-[70%] bottom-0 right-0  ml-auto border-[12px] border-white  lg:border-0  lg:mx-auto h-[300px] md:h-[500px] lg:h-60 lg:w-96'>
              <Image
                src={AboutImgTwo}
                alt=''
                layout='responsive'
                width={500}
                height={360}
                objectFit='cover'
                sizes='(min-width: 75em) 33vw,
              (min-width: 48em) 50vw,
              100vw'
              />
            </div>
          </div>

          <div className='w-full lg:w-7/12 order-1 lg:order-2 flex flex-col  lg:items-start px-6 lg:p-0 '>
            <h4 className='SlideInTop text-4xl lg:text-6xl font-display mt-10 lg:mt-0 mb-10 tracking-[3px] uppercase'>
              <span className='capitalize italic font-display'>Leading </span>
              Ladies,
            </h4>
            <p className='SlideInBottom text-lg max-w-2xl lg:pr-8 mb-4'>
              Leading Ladies is a House of Praise Ministry that celebrates and
              empowers pastors’ wives to achieve their God-given destiny and be
              a positive influence on their community.
              <br />
              <br />
              Leading Ladies Ministry is inspired by my personal experience and
              that of other pastors’ wives. Although the position of a “Pastor’s
              Wife” is an all encompassing role, it does not come with a job
              description. Like other pastors’ wives, I found myself constantly
              wondering how to effectively navigate this position. How to
              effectively serve my husband, family and church community whilst
              trying to avoid internalizing pain and frustration.
              <br />
              <br />
              This is a community of like-minded pastors’ wives who are
              dedicated to serving God’s people and advancing His Kingdom. It is
              a platform that will celebrate and empower us through the
              provision of divinely inspired teachings, mentorship sessions and
              events. These resources will aid in navigating and managing our
              relationship with the church community and our spouse in his role
              as our husband and as the senior pastor from God’s point of view.
              <br />
              <br />
              You are appreciated! You are loved! Your community values you!{" "}
              {/* 
              It’s time to celebrate you! We have put together a special evening
              to honour our esteemed pastors’ wives.
              <br />
              <br />
              <strong>Date: 13th of August at 5pm.</strong> It will be a time of
              celebration, a time to fellowship with like-minded pastors&apos;
              wives who are dedicated to serving God&apos;s people and advancing
              His Kingdom.&nbsp; */}
              {/* <Link 
                href='https://hopevents.typeform.com/leadingladies'
                passHref
              >
                <a
                  target='_blank'
                  className='font-bold italic text-brand-orange cursor-pointer underline-offset-2 underline tracking-wide'
                >
                  Register Here!
                </a>
              </Link>*/}
            </p>

            <div className='relative mb-6 mt-2 lg:mb-0 w-60 h-36 '>
              <Brand color='black' w='240' brand='PTA' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
