import ArticleOne from "../assets/images/article_one.jpeg";
import ArticleTwo from "../assets/images/article_two.jpeg";
import Image from "next/image";
import Link from "next/link";
import { useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import {
  animateSlideInBottom,
  animateSlideInLeft,
  animateTextFade,
} from "./animations";
gsap.registerPlugin(ScrollTrigger);

const Community = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const SectionRef = sectionRef.current;
    animateTextFade(SectionRef.querySelectorAll("#Community h5"));
    animateSlideInBottom(
      SectionRef.querySelectorAll("#Community .Animated--SubText"),
      1.3
    );
  }, []);
  return (
    <section id='Community' className='' ref={sectionRef}>
      <div className='max-w-screen-2xl mx-auto'>
        <div className='flex flex-col lg:flex-row justify-between items-center mx-auto '>
          <div className='order-2 lg:order-1 w-full lg:w-1/2 flex justify-center items-center'>
            <div className='max-w-lg pt-10 lg:pt-0 lg:px-12 xl:px-0 flex flex-col lg:block'>
              <h5
                className=' mx-auto text-xl sm:text-2xl text-center lg:text-left md:text-3xl xl:text-4xl 
              font-bold font-sans lg:font-display uppercase tracking-[3px]  leading-tight lg:leading-none text-[40px] mb-6'
              >
                Be Empowered through{" "}
                <span className='signature lowercase lg:text-[36px] xl:text-[42px]'>
                  discipleship
                </span>
              </h5>
              <div className='Animated--SubText text-[17px] max-w-md leading-[29px] mb-8 text-center lg:text-left px-4 lg:px-0'>
                Here, you will find divinely inspired resources to guide you in
                navigating the position of a pastor’s wife.
                <div className='flex justify-center lg:justify-start mt-8 lg:pr-6'>
                  <Link href='/toolbox'>
                    <a
                      aria-label="Get started with The Leading Ladies' Institute"
                      className='cursor-pointer relative inline-flex justify-center items-center 
              w-40 h-10 overflow-hidden text-white bg-black rounded-lg group focus:outline-none focus:ring 
              '
                    >
                      <span className='absolute right-0 transition-transform translate-x-full group-hover:-translate-x-4'>
                        <svg
                          className='w-5 h-5'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                        >
                          <path strokeWidth='2' d='M17 8l4 4m0 0l-4 4m4-4H3' />
                        </svg>
                      </span>

                      <span className='transition-all font-btn group-hover:mr-4'>
                        Get Started
                      </span>
                    </a>
                  </Link>
                </div>
              </div>{" "}
            </div>
          </div>
          <div className='ArticleFigOne duration-500 order-1 w-full lg:w-1/2 flex relative h-[404px]'>
            <Image
              src={ArticleOne}
              alt=''
              layout='fill'
              objectFit='cover'
              sizes='(min-width: 75em) 33vw,
              (min-width: 48em) 50vw,
              100vw'
            />
          </div>
        </div>

        <div className='flex flex-col lg:flex-row justify-between items-center mx-auto'>
          <div className='order-2 w-full lg:w-1/2 flex justify-center items-center'>
            <div className='xl:max-w-lg pt-10 lg:pt-0 lg:pl-16 lg:pr-10 xl:px-0 flex flex-col lg:block'>
              <h5 className='Animated--Text mx-auto text-xl sm:text-2xl text-center lg:text-left md:text-3xl xl:text-4xl font-bold font-sans lg:font-display uppercase tracking-[3px]  leading-tight lg:leading-none text-[40px] mb-6'>
                At no cost to{" "}
                <span className='signature capitalize lg:text-[36px] xl:text-[42px]'>
                  You
                </span>
              </h5>
              <div className='Animated--SubText text-[17px] max-w-md leading-[29px] mb-8 text-center lg:text-left px-4 lg:px-0'>
                Enjoy the free &nbsp;
                <Link href='/toolbox'>
                  <a target='_blank' className='underline underline-offset-2'>
                    toolbox
                  </a>
                </Link>{" "}
                and available{" "}
                <Link href='https://topeakinsiku.com'>
                  <a target='_blank' className='underline underline-offset-2'>
                    resources
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className='ArticleFigTwo duration-500 order-1 w-full mt-12 lg:mt-0  lg:w-1/2 pt-6 lg:pr-6  relative lg:-translate-y-8 lg:translate-x-12'>
            <div className='hidden lg:block absolute bg-white h-8 w-12 Dotted--Bkg top-0 lg:right-2 xl:right-0'></div>
            <div className='flex relative  h-[426px] lg:scale-x-110 lg:-translate-y-4 lg:-translate-x-5'>
              <Image
                src={ArticleTwo}
                alt='Background Image'
                layout='fill'
                objectFit='cover'
                sizes='(min-width: 75em) 33vw,
              (min-width: 48em) 50vw,
              100vw'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Community;
