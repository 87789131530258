import Image from "next/image";

const Hero = ({ imgSrc, bkg }) => {
  return (
    <section className='bg-gradient-to-br from-brand-purple to-brand-peach-medium relative'>
      <div className='absolute top-0 left-0 w-full h-full overflow-hidden'>
        <Image
          src={bkg}
          alt='Background Image'
          layout='responsive'
          width={1200}
          height={560}
          objectFit='cover'
          priority={true}
          sizes='(min-width: 75em) 33vw,
              (min-width: 48em) 50vw,
              100vw'
        />
      </div>
      <div className='container Hero--Wrapper  mx-auto'>
        <div className='flex flex-col lg:flex-row justify-center lg:items-center'>
          <div className='w-full lg:w-1/2 order-2 lg:order-1 px-8'>
            <div className=' py-14 h-full mx-auto flex flex-col justify-center font-normal '>
              <h4 className='z-10 uppercase font-bold text-3xl mb-6'>
                Our mission
              </h4>
              <p className='z-10 text-xl md:text-3xl lg:text-4xl tracking lg:leading-normal  lg:max-w-xl'>
                “Empowering Pastors’ wives in understanding their assignments
                and making &nbsp;
                <span className='font-display italic'>positive impacts</span>
                &nbsp; through their leadership position.”
              </p>
            </div>
          </div>
          <div className='w-full h-full  lg:w-1/2 order-1 lg:order-2 '>
            <div className='relative w-full '>
              <div className='relative w-full flex items-center h-full'>
                <div className='hidden lg:block circle overflow-hidden relative h-full w-full z-20 lg:translate-x-8 '>
                  <Image
                    src={imgSrc}
                    alt=''
                    layout='responsive'
                    height={560}
                    width={560}
                    objectFit='cover'
                    objectPosition='center 25%'
                    priority={true}
                    sizes='(min-width: 75em) 33vw,
              (min-width: 48em) 50vw,
              100vw'
                  />
                </div>
                <div className='lg:hidden overflow-hidden relative h-full w-full z-20 lg:translate-x-8'>
                  <Image
                    src={imgSrc}
                    alt=''
                    layout='responsive'
                    height={500}
                    width={500}
                    objectFit='cover'
                    priority={true}
                    objectPosition='center 5%'
                    sizes='(min-width: 75em) 33vw,
              (min-width: 48em) 50vw,
              100vw'
                  />
                </div>
                <div className='absolute hidden  lg:block circle top-0 left-0 w-full h-full bg-transparent border-white border-8 opacity-50'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
