import Image from "next/image";
import { useRef, useEffect } from "react";
import {
  animateDescend,
  animateSlideInBottomRight,
  animateSlideInLeft,
  animateSlideInUpLeft,
  animateText,
} from "./animations";

const ImageWithText = ({ imgSrc, title, children }) => {
  const titleRef = useRef(null);
  const sectionRef = useRef(null);

  useEffect(() => {
    const SectionRef = sectionRef.current;
    animateSlideInLeft(
      SectionRef.querySelectorAll(".Image-With-Text--Section .SlideInLeft"),
      1.6
    );
    animateSlideInUpLeft(
      SectionRef.querySelectorAll(".Image-With-Text--Section .SlideInUpLeft"),
      1.3
    );
    animateSlideInBottomRight(
      SectionRef.querySelectorAll(
        ".Image-With-Text--Section .SlideInBottomRight"
      ),
      1.4
    );
    animateText(
      SectionRef.querySelectorAll(".Image-With-Text--Section .FadeInText"),
      2
    );
    animateDescend(
      SectionRef.querySelectorAll(".Image-With-Text--Section .Descend"),
      2
    );
  }, []);
  return (
    <section
      className='Image-With-Text--Section mt-12 lg:mt-0 pt-8'
      ref={sectionRef}
    >
      <div className='hidden lg:grid w-full grid-cols-16 grid-rows-16 '>
        <div className='SlideInLeft col-start-1 col-end-11 row-start-2  row-end-10 bg-brand-green shadow-md'></div>

        <div className='SlideInBottomRight bg-brand-peach-light col-start-10 col-end-17 row-start-5 row-end-16 shadow-sm'></div>

        <div className='SlideInUpLeft relative col-start-1 col-end-13 row-start-3 row-end-15 z-10 xl:min-h-[418px] 2xl:min-h-[560px] shadow-md'>
          <Image
            src={imgSrc}
            alt='Background Image'
            layout='fill'
            objectFit='cover'
            loading='lazy'
            lazyBoundary='1000px'
          />
        </div>

        <div
          className='Descend z-20 row-start-3 row-end-5 col-start-14 col-end-17 flex items-center'
          ref={titleRef}
        >
          <h4 className='font-bold text-3xl uppercase tracking-[3px] mb-4'>
            {title}{" "}
            <span className='capitalize font-signature text-brand-orange text-[40px] font-normal'>
              Topsy
            </span>
          </h4>
        </div>

        <div className='col-start-14 col-end-17 row-start-6 row-end-15 z-20 flex items-center'>
          <p className='text-lg 3xl:text-xl max-w-lg lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl leading-relaxed'>
            {children}
          </p>
        </div>
      </div>
      <div className='lg:hidden flex flex-col items-stretch'>
        <div className='flex-1 relative pt-8 lg:mb-4'>
          <div className='SlideInLeft bg-brand-green absolute h-5/6 w-5/6 absolute top-0 left-0'></div>
          <div className='SlideInUpLeft  w-full aspect-video relative overflow-hidden'>
            <Image
              src={imgSrc}
              alt='Background Image'
              layout='responsive'
              width={1200}
              height={700}
              objectFit='cover'
            />
          </div>
        </div>
        <div className=' container flex flex-col justify-center lg:items-center bg-brand-peach-light'>
          <h4 className='FadeInText font-bold text-xl uppercase tracking-[3px] mt-8 mb-8 mx-1'>
            {title}
            <span className='capitalize text-3xl font-normal font-signature '>
              Topsy
            </span>
          </h4>
          <p className='FadeInText text-base leading-loose sm:leading-relaxed sm:text-xl xl:text-2xl w-11/12 mb-8 mx-1'>
            {children}
          </p>
        </div>
      </div>
    </section>
  );
};

export default ImageWithText;
