import Image from "next/image";
import Link from "next/link";
import CoursePreviewImg from "../assets/images/course_preview.jpeg";
import { useEffect, useRef } from "react";
import {
  animateDescend,
  animateSlideInBottom,
  animateSlideInRight,
  animateSlideInTop,
} from "./animations";

const CoursePreview = () => {
  const sectionRef = useRef(null);
  useEffect(() => {
    const SectionRef = sectionRef.current;
    animateDescend(
      SectionRef.querySelectorAll("#Toolbox--Preview .Section--Background"),
      2
    );
    animateSlideInRight(
      SectionRef.querySelectorAll("#Toolbox--Preview .Decor--Text "),
      1.3
    );
    animateSlideInTop(
      SectionRef.querySelectorAll("#Toolbox--Preview .SlideInTop"),
      1.3
    );
    animateSlideInBottom(
      SectionRef.querySelectorAll("#Toolbox--Preview .SlideInBottom"),
      1.3
    );
  }, []);
  return (
    <section
      id='Toolbox--Preview'
      className=' rounded-lg relative '
      ref={sectionRef}
    >
      <div className='relative  flex items-center h-[512px] md:h-[560px] lg:h-[600px]'>
        <div className='hidden lg:block absolute right-3 top-5'>
          <div className='Decor--Text font-signature text-brand-orange/30 text-6xl -rotate-[35deg] '>
            you are
            <br /> a leader
          </div>
        </div>
        <div className='Section--Background'></div>
        <div id='Course--Preview-Wrapper' className='container'>
          <div className='relative flex flex-col max-w-6xl mx-auto '>
            <div className='absolute left-0 bottom-0 -translate-x-full flex flex-col -ml-4'>
              <h4 className='Sideways--BT SlideInBottom tracking-[1px] order-2'>
                {" "}
                Explore
              </h4>
              <span className='hidden lg:block py-16 px-[1px] mr-3 mb-4 bg-text-charcoal'></span>
            </div>

            <h4 className='Section--Heading SlideInBottom'>
              Leading Ladies Toolbox
            </h4>
            <div className='SlideInTop relative'>
              <div className='absolute w-full h-full'>
                <div className='w-full h-full relative overflow-hidden'>
                  <div className='absolute bg-black/30 w-full h-full z-10 '></div>
                  <div className='lg:hidden'>
                    <Image
                      src={CoursePreviewImg}
                      alt='Background Image'
                      layout='responsive'
                      height={1600}
                      width={1600}
                      objectFit='cover'
                      objectPosition={"center"}
                    />
                  </div>
                  <div className='hidden lg:block'>
                    <Image
                      src={CoursePreviewImg}
                      alt='Background Image'
                      layout='responsive'
                      height={800}
                      width={1600}
                      objectFit='cover'
                      objectPosition={"center"}
                    />
                  </div>
                </div>
              </div>
              <div className='z-20 py-20 flex flex-col  items-center justify-center '>
                <h3 className='z-20 text-3xl lg:text-4xl xl:text-6xl text-white tracking-tight mb-6 text-center '>
                  The Strategic Focus To Your Role
                </h3>
                <Link href='/toolbox'>
                  <a className='z-20 relative inline-flex justify-center items-center w-40 h-10 overflow-hidden text-black bg-white rounded-lg group focus:outline-none '>
                    <span className='absolute right-0 transition-transform translate-x-full group-hover:-translate-x-4'>
                      <svg
                        className='w-5 h-5'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                      >
                        <path strokeWidth='2' d='M17 8l4 4m0 0l-4 4m4-4H3' />
                      </svg>
                    </span>

                    <span className='transition-all font-btn group-hover:mr-4'>
                      Watch now
                    </span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoursePreview;
