import Image from "next/image";
import { useEffect } from "react";
import { useRef } from "react";
import ConfessionImg from "../assets/images/reflection.jpeg";
import {
  animateCascadeLeft,
  animateSlideInBottom,
  animateSlideInLeft,
} from "./animations";

const Confession = () => {
  const sectionRef = useRef(null);
  useEffect(() => {
    const SectionRef = sectionRef.current;
    animateSlideInBottom(
      SectionRef.querySelectorAll("#Confession--Section .SlideInLeft"),
      1.8,
      {
        ease: "back",
      }
    );
    animateSlideInBottom(
      SectionRef.querySelectorAll("#Confession--Section .SlideInBottom")
    );
    animateCascadeLeft(
      SectionRef.querySelectorAll("#Confession--Section .CascadeLeft div"),
      1.5
    );
  }, []);
  return (
    <section id='Confession--Section' className=' lg:pt-2 ' ref={sectionRef}>
      <div className='hidden lg:block container'>
        <div className='md:relative '>
          <div className='h-full w-full '>
            <div className='SlideInBottom relative w-1/2  ml-auto h-full'>
              <Image
                src={ConfessionImg}
                alt='PTA @ Fearless'
                layout='responsive'
                objectFit='cover'
                sizes='(min-width: 75em) 33vw,
              (min-width: 48em) 50vw,
              100vw'
              />
            </div>
          </div>
          <div className='lg:absolute  top-0 right-0 lg:h-full w-full flex items-center'>
            {" "}
            <div className='SlideInLeft mr-auto w-full lg:w-[54%] bg-brand-pink-light h-64 xl:h-80  flex flex-col items-center justify-center text-brand-black space-y-4'>
              <h5 className=' uppercase text-3xl text-center font-bold tracking-normal '>
                I am fearless
              </h5>
              <p className='text-[28px] text-center space-y-3'>
                <span className='block leading-none'>
                  because I am his daughter.
                </span>
                <span className='block leading-none'>
                  I am not a second thought;
                </span>
                <span className='block leading-none'>I am his first love.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='lg:hidden w-screen'>
        <div className='CascadeLeft flex flex-col items-strech justify-center'>
          <div className='relative w-full h-72 sm:h-96 overflow-hidden'>
            <Image
              src={ConfessionImg}
              alt='Image'
              layout='fill'
              objectFit='cover'
              sizes='(min-width: 75em) 33vw,
              (min-width: 48em) 50vw,
              100vw'
            />
          </div>
          <div className='w-full'>
            <div className=' ml-auto w-full  bg-brand-pink-light h-72 flex flex-col items-center justify-center text-brand-black space-y-4'>
              <h5 className='Animated--Text uppercase text-3xl text-center font-bold tracking-normal '>
                I am fearless
              </h5>
              <p className='Animated--SubText text-[28px] text-center space-y-3'>
                <span className='block leading-none'>
                  because I am his daughter.
                </span>
                <span className='block leading-none'>
                  I am not a second thought;
                </span>
                <span className='block leading-none'>I am his first love.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Confession;
