import { createClient } from "next-sanity";
import Link from "next/link";
import Head from "next/head";
import {
  About,
  Hero,
  Confession,
  Community,
  InstitutePreview,
  ImageWithText,
} from "../components";
import { Layout } from "../components/layout";
import { Jumbotron } from "../components/snippets";
import { getSession } from "next-auth/react";

import PtaCouch from "../assets/images/pta_couch.jpeg";
import HeroBkg from "../assets/images/hero.png";
import HeroImg from "../assets/images/women.webp";
import Conference from "../components/conference";
import FAQs from "../components/conference/faqs";

export default function Home({ showAuth, faqs }) {
  return (
    <>
      <Head>
        <title>Home | Leading Ladies</title>
        <meta
          name='google-site-verification'
          content='e-gicxPFriLJh50aOnKLOXbfdKvALlV_F-YzumwHGPk'
        />
        <meta
          name='description'
          content='Empowering Pastors’ wives in understanding their assignments and making  positive impacts  through their leadership position.'
        />
      </Head>

      <Layout showAuth={showAuth} showLoading={true} showModal={true}>
        <Jumbotron
          title='Leading Ladies'
          subtitle='A community of passionate, like-minded pastors’ wives dedicated to serving God and advancing His kingdom.'
        />
        <Hero imgSrc={HeroImg} bkg={HeroBkg} />
        <About />
        {/* <Conference bkg={HeroBkg} /> */}
        <InstitutePreview />
        <ImageWithText title='insights from' imgSrc={PtaCouch} auxTitle='Topsy'>
          Twenty-Two years ago, my life changed! (wow, I can&apos;t believe it
          has been 22 years already!)
          <br />
          <br />
          My husband and I moved to Canada to pursue a better life for ourselves
          and our family. Unknowing to us God had a better plan, He called us to
          be pastors and start a dynamic, spirit filled church (House of Praise)
          under the Redeemed Christian Church of God.
          <br />
          <br />
          It has been an extremely rewarding calling, one I will answer 100
          times again. However, stepping into the role of a pastor&apos;s wife
          wasn&apos;t what I had envisioned for my future. I did not marry my
          husband as a Pastor. As a matter of fact, our dream to move to Canada
          was inspired by the pursuit of his journey as a Pharmacist (amongst
          other things). So how did we adjust to this new call on our lives?
          <br />
          <br />I increased my dependency on God and spent more quality time
          with seeking God&apos;s guidiance and direction. I remember one
          specific question I asked God and that was “God help me understand
          your expectation of me” and that was a game changer! God renewed my
          mindset, he led me to mentors who were able to guide me in making the
          right decisions both in my home and in the church. Another thing I did
          to adjust to the role of a pastor&apos;s wife was to understand that
          my husband was not just my husband but he also serves as the senior
          pastor of the church. To navigate that new relationship we had a
          conversation about his expectations of me serving in the capacity of a
          pastor&apos;s wife.
          <br />
          <br />
          In my 22 years of serving in this position, I have had the opportunity
          to mentor and counsel various pastors&apos; wive&apos;s from across
          the globe. I understand that adjusting to the role of a pastor&apos;s
          wife and serving in this capacity comes with certain discomforts and
          nuances that other people might not understand. God sees you. He has
          called you to this position and has equipped you with all you need to
          be successful in serving His people and advancing His Kingdom. God
          says in Isaiah 41:10 NKJV “fear not, for I am with you; be not
          dismayed, for I am your God; I will strengthen you, I will help you, I
          will uphold you with my righteous right hand.”
          <br />
          <br />
          I am super excited and passionate about all God is about to do through
          you!
          <br />
          <br />
          <Link href='https://topeakinsiku.com'>
            <a target='_blank' rel='noopener noreferrer' className=''>
              <span className='italic underline underline-offset-2 text-brand-orange'>
                Find more tools and resources from Tope Akinsiku here
              </span>
            </a>
          </Link>
        </ImageWithText>
        <Community />
        <Confession />
        <FAQs faqs={faqs[0]} />
      </Layout>
    </>
  );
}

export async function getServerSideProps(context) {
  const { req } = context;
  const client = createClient({
    projectId: "2txww5v4",
    dataset: "production",
    apiVersion: "2022-08-10",
    useCdn: true,
  });
  const faqs = await client.fetch(
    `*[_type == "faq" && for == 'conference']{faqs}`
  );

  const session = await getSession({ req });
  if (!session) {
    return {
      props: {
        showAuth: true,
        session: await getSession(context),
        faqs,
      },
    };
  }
  return {
    props: {
      showAuth: false,
      faqs,
    },
  };
}
